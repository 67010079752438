.singleselect {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    margin: 30px auto;
  }
  
  /* .singleselect.col-3 .singleselectlabel {
    flex: 0 0 32%;
    margin-bottom: 0;
  } */
  .singleselect.col-3 .singleselectlabel:last-child {
    margin-right: 0;
  }
  .singleselectlabel {
    color: var(--black-4);
    position: relative;
    margin-bottom: 10px;
    padding: 14px 0;
    padding-left: 45px;
    padding-right: 14px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-family: "ns-semibold";
    border: 1px solid var(--black-4);
    border-radius: 10px;
    /* min-width: max-content; */
    display: block;
  }
  
  .singleselectlabel:last-child {
    margin-bottom: 0;
  }
  
  .singleselectlabel.active {
    background: var(--light-yellow);
  }
  
  .singleselect-btn {
    /* position: absolute; */
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: var(--white);
    border-radius: 50%;
    border: none;
    outline: none;
    opacity: 0;
  }
  .singleselect-btn:focus {
    outline: none;
    border: none;
  }
  .singleselectradio {
    top: 15px;
    left: 15px;
    position: absolute;
    height: 20px;
    width: 20px;
    background: transparent;
    border: 1px solid var(--black-4);
    border-radius: 50%;
  }
  .singleselect-btn:checked ~ .singleselectradio {
    background: transparent;
    border-color: var(--black-4);
  }
  .singleselect-btn:checked ~ .singleselectradio::after {
    display: block;
  }
  .singleselectradio::after {
    display: none;
    content: "";
    top: 3px;
    left: 3px;
    height: 12px;
    width: 12px;
    position: absolute;
    background: var(--black-4);
    border-radius: 50%;
  }
  .radio-group .singleselect-btn:checked {
    color: var(--black-4);
  }
  