.notificationOuter{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    top: 0;
    left: 0;
    width: 100vw;
    top: 65px;
    height: calc(100vh - 65px);
    position: fixed;
    padding: var(--spacing-8px);
    z-index: 10;
    background:  rgba(23, 23, 23, 0.4);
    backdrop-filter: blur(5px);
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
}

.notificationContainer{
    top: -4px;
    position: absolute;
    border-radius: 5px;
    width: 400px;
    height: max-content;
    background: var(--white);
    transform: translateY(30px);
    transition: 0.4s;
    opacity: 0;
}

.notificationContainer::before{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: var(--white);
    left: 53%;
    top: -5px;
    transform: rotate(45deg);
}

.notificationOuter.active{
    visibility: visible;
    opacity: 1;
}
.notificationOuter.active .notificationContainer{
    opacity: 1;
    transform: translateY(0px);
}

.notificationHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--grey-3);
    padding: var(--spacing-12px) var(--spacing-16px);
}

.notificationHeader .heading{
    font-family: 'ns-bold';
}

.notification{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-8px);
    padding:  var(--spacing-12px) var(--spacing-16px);
    border-bottom: 1px solid var(--grey-3);
}

.notificationName{
    font-size: 16px;
}

.notificationDescription{
    font-size: 14px;
    color: var(--black-1);
}

.notification img{
    padding: 10px;
    border-radius: 5px;
    background-color: var(--primary-1);
}

.notificationFooter{
    display: flex;
    gap: var(--spacing-4px);
    align-items: center;
    justify-content: center;
    padding: var(--spacing-12px) var(--spacing-16px);
}

.NotificationFooterText{
    cursor: pointer;
    color: var(--primary-4);
    font-size: 14px;
    font-family: 'ns-semibold';
}