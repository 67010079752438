.uploadPopupContainer {
    width: 600px;
    background: var(--white);
    border-radius: 6px;

}

.uploadFileContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}

.uploadFileContainer .uploadBoxOuter {
    width: 100%;

}

.downloadCsvBtn {
    color: var(--primary-4);
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
    margin-top: var(--spacing-8px);
}