.notificationsOuter{
    width: 100%;
    display: flex;
    padding: var(--spacing-20px);
    align-items: flex-start;
    justify-content: center;
    height: calc(100vh - 110px);
}
.notificationsContainerOuter{
    width: 800px;
    margin: auto;
    height: 100%;
    overflow: auto;
    background: var(--white);
    border: 1px solid var(--grey-3);
    border-radius: 6px;
}

.notificationsContainer {
    padding: var(--spacing-20px) var(--spacing-20px) var(--spacing-12px);
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid var(--grey-3);
}

.notificationsList{
    padding: var(--spacing-12px) 0 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
}

.notificationItem{
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
    padding: var(--spacing-12px) 0;
}

.notificationItem .notification{
    width: 100%;
    padding: 0;
    border: none;
}

.notificationItem .notificationName{
    font-family: 'ns-bold';
}

.notificationsContainer .heading {
    font-family: 'ns-semibold';
}