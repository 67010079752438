.progessBarOuter{
    height: 5px;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    background: var(--grey-3);
}
.progress{
    height: 100%;
    background: var(--primary-6);
}