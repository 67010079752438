.termPlanPopup{
    width: 480px;
    background: var(--white);
    border-radius: 5px;
}

.formPopup{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}