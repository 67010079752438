.feedbackReportContainer{
    width: 700px;
    position: relative;
    height: auto;
    border-radius: 6px;
    background: var(--white);
}

.popupBodyHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.popupBodyHeaderLeft .heading{
    font-size: 20px;
    font-family: 'ns-bold';
    color: var(--black-5);
}

.popupBodyHeaderLeft .reportText{
    font-size: 12px;
}

.popupTableOuter{
    margin-top: var(--spacing-16px);
    width: 100%;
    border-radius: 6px;
    overflow: auto;
    height: 410px;
    border: 1px solid var(--grey-3);
}

.popupTable{
    width: 100%;
    text-align: left;

}

.popupTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.popupTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}

.popupTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.popupTable tbody tr:hover td{
    background: var(--grey-1);
}