.createEventContainer.createClubContainerOne{
    height: max-content;
}

.createEventContainer.createClubContainerOne .createClubContainerFooter{
    position: relative;
}

.createEventContainer.createClubContainerOne .form2Col .formGrp{
    margin: 0;
}

.createEventContainer.createClubContainerOne .formOuter{
    padding-bottom: var(--spacing-32px);
}