.clubMemberContainer{
    width: 800px;
    background: var(--white);
    border-radius: 6px;
}

.clubMemberListTableOuter{
    width: 100%;
    height: 600px;
    overflow: auto;
    border: 1px solid var(--grey-3);
    border-radius: 6px;
}

.clubMemberListTable{
    width: 100%;
    text-align: left;

}

.clubMemberListTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.clubMemberListTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.clubMemberListTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.clubMemberListTable tbody tr:hover td{
    background: var(--grey-1);
}