.feedback-wrapper {
    align-items: flex-start;
    justify-content: center;
    height: calc(100vh - 113px);
}

.with-card .question-box {
    background: var(--white);
    box-shadow: 5px 5px var(--warning-2);
    border: 1px solid var(--black-5);
    border-radius: 5px;
}

.question-box {
    max-width: 80%;
    width: 100%;
    padding: 40px;
    text-align: center;
    min-height: 340px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.question-box .heading-2{
    font-size: 20px;
    line-height: 140%;
    text-align: justify;
}

.question-box .text-3{
    font-size: 18px;
    line-height: 150%;
    text-align: justify;
}

.question-box a {
    text-decoration: none;
    color: var(--primary-5);
}

.heading-2 {
    font-size: 32px;
    line-height: 40px;
    font-family: 'ss-bold';
}

.text-3 {
    font-size: 16px;
    line-height: 22px;
    font-family: 'ns-regular';
    color: var(--black-3);
}

.feedbackDangerBtn {
    background: var(--danger-5);
    padding: 18px 40px;
    width: 100%;
    color: var(--white);
    font-family: "ns-bold";
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    max-width: 170px;
}

span.important {
    color: var(--danger-5);
}

.fullScreeeWrapper{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scrollTop{
    position: relative;
   top: 32vh;
  left: 21.2vw;
 background-color: #DF2935;
 border-radius: 6px;
}

