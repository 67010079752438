.myCoursesOuter{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin: auto;
    height: 100%;
    padding-top: 20px;
}
.myCoursesHeader{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.myCoursesHeader .heading{
    font-size: 28px;
    line-height: 36px;
    color: var(--black-3);
    font-family: 'ns-bold';
    width: 100%;
}

.myCoursesBody{
    margin-top: var(--spacing-20px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var( --spacing-8px);
}

.myCourseCard{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: var(--white);
    border-radius: 8px;
    padding: 24px 20px;
    border: 1px solid var(--grey-3);
    gap: var(--spacing-20px);
}

.myCourseCardHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.myCourseCardHeader .courseTitle{
    font-size: 20px;
    line-height: 28px;
    font-family: 'ns-semibold';
    color: var(--black-5);
}
.myCourseCardBody{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-12px);
    flex-direction: column;
}
.myCourseSessionDetails{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}