.createFeedbackMainContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 55px);
}

.createFeedbackFormContainerOuter {
  padding: var(--spacing-20px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.createFeedbackFormContainer {
  position: relative;
  background: var(--white);
  width: 700px;
  height: 100%;
  border-radius: 6px;
  border: 1px solid var(--grey-3);
  overflow: auto;
}

.createFeedbackForm {
  padding: var(--spacing-20px);
  padding-bottom: 40px !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 28px;
}

.createFeedbackForm .feedbackRadioGroup {
  justify-content: flex-start;
  gap: var(--spacing-24px);
}

.createFeedbackFooter {
  /* position: absolute; */
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid var(--grey-3);
  padding: var(--spacing-16px) var(--spacing-20px);
}

.addFeedbackStudentContainerOuter {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12px);
  width: 100%;
  height: 100%;
  padding: var(--spacing-20px);
}

.addFeedbackStudentContainerOuter .filterBar {
  padding: 0;
}

.addFeedbackTable {
  width: 100%;
  text-align: left;
}

.addFeedbackTable thead {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
}

.addFeedbackTable thead th {
  background: var(--black-4);
  color: var(--white);
  font-family: "ns-semibold";
  font-size: 14px;
  line-height: 20px;
  padding: 12px 20px;
}

.addFeedbackTable thead .checkbox-label span {
  background: transparent;
  border: 1px solid var(--white);
}

.addFeedbackTable tbody td {
  font-size: 14px;
  line-height: 20px;
  color: var(--black-4);
  font-family: "ns-regular";
  background: var(--white);
  padding: 10px 20px;
  border-bottom: 1px solid var(--grey-3);
  transition: 0.3s;
}

.addFeedbackTable tbody tr:hover td {
  background: var(--grey-1);
}

.addFeedbackTableOuter {
  width: 100%;
  border-radius: 6px;
  overflow: auto;
  height: 100%;
  background: var(--white);
}

.feedbackBuilderContainerOuter {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.layoutSelector {
  border-top: 1px solid var(--grey-3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-16px) var(--spacing-20px);
}

.questionMenuOuter {
  position: relative;
  width: 300px;
  height: 100%;
  background: var(--white);
}

.homePage {
  border-top: 1px solid var(--grey-3);
  padding: var(--spacing-12px) var(--spacing-20px);
}

.questionList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16px);
  border-top: 1px solid var(--grey-3);
  padding: var(--spacing-12px) 0 var(--spacing-12px) var(--spacing-20px);
  cursor: pointer;
}

.questionList .question {
  font-size: 12px;
  line-height: 16px;
  color: var(--black-4);
  font-family: "ns-regular";
  margin-bottom: 0;
}

.questionListItem {
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-8px);
}

.questionListItem .detailArea {
  overflow: hidden;
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-8px);
}

.questionListItem .multiicons {
  max-width: 16px;
}

.editIcon:hover path {
  fill: var(--primary-5);
  transition: 0.4s ease-in-out;
}

.removeIcon:hover path {
  fill: var(--danger-5);
  transition: 0.4s ease-in-out;
}

.mutiiconsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease-in-out;
}

.questionList:hover .mutiiconsWrapper {
  opacity: 1;
  visibility: visible;
  transition: 0.4s ease-in-out;
}

.addQuestionMenuItem {
  border-bottom: 1px solid var(--grey-3);
  padding: var(--spacing-8px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-8px);
  font-size: 12px;
  transition: 0.3s;
}

.addQuestionMenuItem:last-child {
  border: none;
}

.addQuestionMenuItem:hover {
  background: var(--grey-3);
}

.addQuestionMenu {
  border-radius: 6px;
  border: 1px solid var(--grey-3);
  background: var(--white);
  display: flex;
  flex-direction: column;
  width: 150px;
  position: absolute;
  top: 50px;
  left: 20px;
  box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1),
    0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.addQuestionMenu.active {
  opacity: 1;
  visibility: visible;
}

.addQuestion {
  cursor: pointer;
  border-top: 1px solid var(--grey-3);
  padding: var(--spacing-16px) var(--spacing-20px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-8px);
}

.addQuestion .dangerText {
  font-size: 14px;
}

.feedbackSlidePreview {
  gap: var(--spacing-20px);
  overflow: auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 300px);
  height: 100%;
  padding: var(--spacing-60px);
}

.feedbackSlide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: auto;
  min-height: max-content;
  background: var(--white);
  padding: var(--spacing-64px) 0;
}

.feedbackSlideContent {
  height: max-content;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-20px);
  width: 90%;
  padding: var(--spacing-40px);
  text-align: center;
}

.feedbackSlideContent .feedbackTitle {
  font-family: "ss-bold";
  font-size: 20px;
}

.feedbackSlideContent .subContent {
  font-size: 14px;
  color: var(--black-1);
}

.cardlayout {
  box-shadow: 5px 5px var(--warning-7);
  border: 1px solid var(--black-5);
  background: var(--white);
}

.questionSelector {
  border-bottom: 1px solid var(--grey-3);
  padding: var(--spacing-16px) var(--spacing-20px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.questionSelector .checkboxContainer {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: var(--spacing-12px);
  padding-left: 23px;
}

.questionDetailsOuter {
  overflow: auto;
  position: absolute;
  height: 100%;
  top: 0;
  left: -300px;
  background: var(--white);
  transition: 0.4s;
  visibility: hidden;
  opacity: 0;
}

.questionDetailsOuter.active {
  left: 0;
  visibility: visible;
  opacity: 1;
}

.questionHeader {
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-4px);
  border-bottom: 1px solid var(--grey-3);
  padding: var(--spacing-16px) var(--spacing-20px);
}

.feedbackSlideContent .checkmarkContainerOuter {
  border: 2px solid var(--black-4);
  border-radius: 6px;
  padding: var(--spacing-8px);
}

.feedbackSlideContent .checkboxContainer {
  justify-content: flex-start;
  text-align: left;
  padding-left: 30px;
}

.feedbackCheckboxOuter {
  width: 100%;
  gap: var(--spacing-20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.optionBox {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--grey-5);
  border-radius: 6px;
}

.optionNumber {
  width: 72px;
  font-size: 12px;
  font-family: "ns-semibold";
  border-right: 1px solid var(--grey-5);
  padding: var(--spacing-8px);
  background: var(--grey-2);
}

.optionBox input {
  border: none;
  outline: none;
  font-size: 12px;
  width: calc(100% - 102px);
}

.optionBox img {
  visibility: hidden;
  opacity: 0;
  padding: var(--spacing-4px);
  transition: 0.3s;
}

.optionBox:hover img {
  visibility: visible;
  opacity: 1;
}

.questionSelector .addCourseBtn {
  margin-top: var(--spacing-8px);
}

.gap10 {
  gap: 10px;
}

/* .fixed-bottom-button{
    position: fixed;
    bottom: -1px;
    width: 300px;
    background: var(--danger-5);
} */

/* .fixed-bottom-button{
    position: fixed;
    bottom: 0;
    width: 300px;
    margin-left: 0;
    border-top: 1px solid var(--grey-3);
    background: var(--white);
} */

.fixed-bottom-button .addCourseBtn {
  background: var(--primary-5);
  padding: 10px 20px;
  justify-content: center;
  border-color: var(--primary-5);
  max-width: max-content;
  margin-left: auto;
  border-radius: 8px;
}

.fixed-bottom-button .addCourseBtn {
  margin-top: 0;
  color: var(--white);
  justify-content: center;
}

.ratingWrapper .starRating {
  gap: 10px;
}

.radioFeedbackWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.radioFeedbackWrapper .radioBtn {
  position: relative;
  padding: 10px;
  border: 1px solid var(--black-1);
  border-radius: 5px;
  min-width: 120px;
  padding: 10px 15px;
}

.radioFeedbackWrapper .radioBtn.active {
  background: var(--warning-7);
}

.radioFeedbackWrapper .radioBtn label {
  text-align: left;
}

.radioFeedbackWrapper [type="radio"]:checked + label:after {
  background: #000;
}

.radioFeedbackWrapper [type="radio"]:checked + label:before {
  border-color: var(--black) !important;
}

.radioFeedbackWrapper [type="radio"]:checked + label {
  color: var(--black);
}

.checkMarkOption {
  position: relative;
}

.optionSelectorSetting {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.optionSelectorSetting .optionBox {
  width: 100%;
}

.optionSelectorSetting label {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  color: var(--primary-5);
  align-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
}

.optionSelectorSetting input[type="checkbox"] {
  display: none;
}

.checkmarkNew {
  height: 16px;
  width: 16px;
  background-color: #eee;
  border: 1px solid var(--primary-5);
  border-radius: 4px;
  position: relative;
}

.optionSelectorSetting input[type="checkbox"]:checked ~ .checkmarkNew {
  background: var(--primary-5);
}

.checkmarkNew::after {
  position: absolute;
  content: "";
  left: 4px;
  top: 1px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.optionSelectorSetting input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}

.addStudentFix {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: -10px;
}

.addStudentFix .searchBox {
  max-width: calc(50% - 10px);
  width: 100%;
}

.twoSettle {
  flex-wrap: nowrap;
  gap: 10px;
}

.OptionBoxNew2 {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
}

.OptionBoxNew2 label {
  display: block;
  color: var(--black-5);
  font-family: "ns-semibold";
  font-size: 14px;
  line-height: 20px;
  width: 100%;
}

.OptionBoxNew2 select {
  width: 100%;
  max-width: 100% !important;
  background: var(--white);
  outline: 1px solid var(--grey-4);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 8px;
  min-width: 260px;
  transition: 0.2s ease-in-out;
  border: none;
  color: var(--black-5);
}
.FeedbackOptionError {
  width: 100%;
  display: block;
  color: #de0000 !important;
  font-size: 14px;
}
