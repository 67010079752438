.sessionRecordContainerOuter{
    width: 100%;
    display: flex;
    gap: var(--spacing-8px);
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: var(--spacing-20px);
    height: calc(100vh - 105px);
    overflow: auto;
}
.sessionRecordContainer{
    width: 700px;
    padding: var(--spacing-20px);
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    background: var(--white);
}

.ticketDetailsBody .successBtn{
    margin-top: var(--spacing-12px);
}

.ticketDetailsBody .dangerOutlineBtnSmall{
    margin-top: var(--spacing-12px);
}

.greyText{
    color: var(--grey-5);
}

.ticketDetailsBody .greyText{
    font-size: 14px;
}

.warningTag {
    width: max-content;
    font-size: 12px;
    line-height: 16px;
    font-family: 'ns-semibold';
    padding: 4px 8px;
    color: var(--black-4);
    border-radius: 2px;
    background-color: var(--warning-2);
}

.feedbackQnA{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-4px);
    margin-top: var(--spacing-12px);
}

.feedbackQuestion{
    font-size: 14px;
    color: var(--black-1);
}

.feedbackAnswer{
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--black-4);
}