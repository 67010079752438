.popupBody .heading{
    font-size: 20px;
    font-family: 'ns-semibold';
}
.assignmentDownloadBtn{
    width: max-content;
}
.assignmentDownloadBtn a{
    text-decoration: none;
}
.generalQuestionContainer .heading{
    font-size: 22px;
    margin-bottom: 10px;
}

.generalQuestionContainer .question{
    font-size: 14px;
    font-family: 'ns-semibold';
}