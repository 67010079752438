.leaveDetailsContainerOuter{
    display: flex;
    width: 100%;
    padding: var(--spacing-20px);
    height: calc(100vh - 120px);
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    flex-direction: column;
    gap: var(--spacing-8px);
}

.leaveDetailsContainer{
    padding: var(--spacing-20px);
    width: 700px;
    position: relative;
    height: auto;
    border: 1px solid var(--grey-3);
    border-radius: 6px;
    background: var(--white);
}

.leaveDetailsHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: var(--spacing-16px);
    border-bottom: 1px solid var(--grey-3);
}

.leaveDetailsHeader .heading{
    font-size: 16px;
    font-family: 'ns-semibold';
    color: var(--black-4);
}

.leaveDetailsTable{
    padding: var(--spacing-16px) 0;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: flex-start;
}

.leaveDetailsTableElement{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 25%;
    gap: var(--spacing-4px);
    flex-direction: column;
}

.leaveDetailsTableElement .heading{
    font-size: 14px;
    color: var(--black-1);
}

.leaveDetailsTableElement .content{
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--black-5);
}

.leaveDetailsContainer .descriptionHeading{
    font-size: 14px;
    color: var(--black-1);
}

.leaveDetailsContainer .description{
    font-size: 14px;
    margin-top: var(--spacing-4px);
    color: var(--black-1);
}

.leaveDetailsContainer .supportingDocHeading{
    margin-top: var(--spacing-16px);
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--black-5);
}

.supportingDocumentContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
    margin-top: var(--spacing-8px);
}

.missingTableContainer{
    margin-top: var(--spacing-16px);
    width: 100%;
    border-radius: 6px;
    overflow: auto;
    border: 1px solid var(--grey-3);
    max-height: 291px;
}

.missingLectureTable{
    width: 100%;
    text-align: left;

}

.missingLectureTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.missingLectureTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.missingLectureTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.missingLectureTable tbody tr:hover td{
    background: var(--grey-1);
}

.missingLectureTable tbody tr:last-child td{
    border: none;
}

.rejectionHeading{
    font-size: 14px;
    font-family: 'ns-semibold';
}

.rejectionReason{
    margin-top: var(--spacing-8px);
    font-size: 14px;
    color: var(--black-2);
}

.pageHeaderBtnGrop{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing-8px);
}