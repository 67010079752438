.createMeetingContainerOuter{
    width: 100%;
    padding: var(--spacing-20px);
    display: flex;
    height: calc(100% - 100px);
    align-items: flex-start;
    justify-content: center;
}

.createMeetingContainer{
    width: 700px;
    border-radius: 6px;
    height: auto;
    background: var(--white);
}

.createMeetingForm{
    display: flex;
    gap: var(--spacing-8px);
    flex-direction: column;
    width: 100%;
    padding: var(--spacing-20px);
}

.createMeetingFooter{
    border-top: 1px solid var(--grey-3);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: var(--spacing-16px) var(--spacing-20px);
}

.createMeetingForm .form2Col{
    gap: var(--spacing-16px);
}