.addStudentPopupContainer{
    width: 860px;
    height: auto;
    background: var(--white);
    border-radius: 6px;
}

.addStudentTableContainer{
    width: 100%;
    height: 600px;
    border-radius: 6px;
    overflow: auto;
    border: 1px solid var(--grey-3);
}
.addStudentTable{
    width: 100%;
    text-align: left;

}

.addStudentTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.addStudentTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.addStudentTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.addStudentTable tbody tr:hover td{
    background: var(--grey-1);
}

.addStudentTable .checkboxContainer{
    padding-left: 0;
}

.addStudentTable .checkmark {
    top: -5px;
}