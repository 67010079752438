.confirmationPopupContainer{
    width: 420px;
    height: max-content;
    background: var(--white);
    border-radius: 6px;

}

.popupBtnGrp{
    gap: var(--spacing-12px);
    margin-top: var(--spacing-40px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.popupText{
    font-size: 16px;
    line-height: 150%;
}
.popupText b{
    font-family: 'ns-bold';
}

.popupTitle{
    font-family: 'ns-semibold';
}