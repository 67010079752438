.emailPopupContainer{
    position: relative;
    width: 700px;
    background: var(--white);
    border-radius: 6px;
    overflow: hidden;
    height: 650px;
}

.emailsDetailsOuter{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.emailsDetails{
    width: 50%;
}
.emailsDetails .heading{
    color: var(--black-1);
    font-size: 14px;
}
.emailsDetails .content{
    font-size: 14px;
    margin-top: 5px;
    color: var(--black-4);
    font-family: 'ns-semibold';
}
.emailHeading{
    margin-top: var(--spacing-16px);
    color: var(--black-1);
    font-size: 14px;
}
.emailPreview{
    width: 100%;
    overflow: auto;
    height: 300px;
}

.emailPopupContainer .popupFooter{
    gap: var(--spacing-8px);
}