.clubListContainer{
    width: 100%;
    height: 100%;
}

.clubListTableContainer{
    width: calc(100% - 40px);
    margin: 20px auto 0;
    height: 100%;
    background: var(--white);
    max-height: calc(100% - 130px);
    overflow: auto;
    border-radius: 6px;
}

.emptyTableBox{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--spacing-8px);
}

.emptyTableBox .heading{
    font-size: 28px;
    line-height: 36px;
    color: var(--black-4);
    font-family: 'ns-bold';
}

.emptyTableBox .text{
    width: 410px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: var(--black-3);
    font-family: 'ns-regular';
}

.clubListTable{
    width: 100%;
    text-align: left;
}

.clubListTable th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}

.clubListTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.clubListTable tbody tr:hover td{
    background: var(--grey-1);
}

.primaryText{
    color: var(--primary-4) !important;
}

.dangerText{
    color: var(--danger-4);
}