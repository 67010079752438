.assigmentListOuter {
    width: calc(100% - 250px);
    padding: var(--spacing-20px);
    margin: 0 auto;
    height: calc(100% - 20px);
    overflow: hidden;
}

.assignmentListTableOuter{
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    background: var(--white);
    height: calc(100% - 60px);
    overflow: auto;
}

.assignmentListTable{
    width: 100%;
    text-align: left;

}

.assignmentListTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.assignmentListTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.assignmentListTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.assignmentListTable tbody tr:hover td{
    background: var(--grey-1);
}

.createAssignmentContainerOuter{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: var(--spacing-20px);
    height: calc(100% - 60px);
}

.createAssignmentContainer{
    display: flex;
    flex-direction: column;
    width: 700px;
    height: 100%;
    border-radius: 6px;
    background: var(--white);
}

.createAssignmentForm{
    height: auto;
    overflow: auto;
    padding: var(--spacing-20px);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16px);
}

.createAssignmentContainer .form2Col{
    justify-content: space-between;
}
.createAssignmentContainer .form2Col .formGroup{
    width: 49%;
    margin: 0;
}

.createAssignmentContainer .formGrp{
    width: 49%;
    margin: 0;
}

.form2Col.flexEnd{
    gap: var(--spacing-12px);
    justify-content: flex-start;
    align-items: flex-end;
}
.dFlexTd{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.assignmentListHeader{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
}