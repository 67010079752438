.feedbackRadioGroup{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.feedbackRadioGroup .label{
    font-size: 14px;
    color: var(--black-1);
}
.sessionFeedbackBody{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-24px);
    overflow: auto;
    height: calc(100% - 110px);
}

.feedbackRadioGroup.flexStart{
    justify-content: flex-start;
    gap: var(--spacing-60px);
}

.popupFooter{
    background: var(--white);
    border-top: 1px solid var(--grey-3);
    padding: var(--spacing-16px) var(--spacing-20px);
}