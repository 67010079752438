.addCourseContainer{
    display: none;
    align-items: flex-start;
    justify-content: space-between;
    width: 1070px;
    margin: auto;
    padding: var(--spacing-20px) 0;
    height: calc(100% - 60px);
}
.addCourseContainer.active{
    display: flex;
}
.addCourseLeft{
    width: 350px;
    height: calc(90vh  - 100px);
    overflow: auto;
}


.addCourseRight{
    position: relative;
    overflow: auto;
    border-radius: 6px;
    width: 700px;
    background: var(--white);
}
.addCourseRightInner{
    padding: var(--spacing-20px);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 530px;
    overflow: auto;
    justify-content: flex-start;
    align-items: flex-end;
    gap: var(--spacing-28px);

}
.addCourseForm{
    display: none;
    flex-direction: column;
    gap: var(--spacing-16px);
    padding: 1px;
    width: 100%;
    height: auto;
}
.addCourseForm.active{
    display: flex;
}
.addCourseFooter{
    border-top: 1px solid var(--grey-3);
    padding: var(--spacing-16px) var(--spacing-20px);
    position: sticky;
    background: var(--white);
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.addCourseBtn{
    cursor: pointer;
    color: var(--danger-4);
    font-size: 14px;
    font-family: 'ns-semibold';
    gap: var(--spacing-4px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.addedCourseCard{
    display: flex;
    flex-direction: column;
    gap: var(--spacing-12px);
    width: 100%;
    padding: var(--spacing-20px);
    border: 1px solid var(--grey-3);
    border-radius: 6px;
    background: var(--grey-1);
}

.addCourseCardHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.addCourseCardHeader .imgGrp{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-12px);
}

.addedCourseCard .heading{
    font-size: 20px;
    font-family: 'ns-semibold';
    color: var(--black-4);
}

.addCourseLeft .addCourseBtn{
    float: right;
    margin-top: var(--spacing-12px);
}

p.addCourseLeftHeading {
    font-size: 14px;
    font-family: 'ns-semibold';
    margin-bottom: var(--spacing-12px);
    text-transform: uppercase;
}

.addCourseRightInner .form2Col{
    gap: var(--spacing-16px);
}

.sessionList{
    display: none;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing-8px);
    padding: var(--spacing-20px) var(--spacing-16px);
    border-top: 1px solid var(--grey-3);
}

.sessionListItem{
    padding: var(--spacing-12px) var(--spacing-20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.sessionListItemLeft{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
    font-size: 14px;
}

.courseDetailsLeftMenuItem{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.courseDetailsLeftMenuItem.active {
    border-radius: 8px;
    border: 1px solid var(--grey-3);
    background: var(--white);
}

.courseDetailsLeftMenuItemInner{
    padding: var(--spacing-20px)  var(--spacing-16px);
}

.courseDetailsLeftMenuItemInner .currentStatus{
    margin-top: 0;
    margin-bottom: var(--spacing-8px);
}

.sessionListItem.active{
    background: var(--success-1);
    border-radius: 6px;
    color: var(--success-4);
}

.sessionListItem.active svg path{
    fill: var(--success-4);
}

.courseDetailsLeftMenuItem.active .sessionList{
    display: flex;
}

.courseMenu{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing-12px);
}

.courseDetailsLeftMenuItem.active  .sessionCardHeader img{
    transform: rotate(180deg);
}

.addCourseForm .formGrp{
    width: 100%;
    gap: 5px;
    margin: 0;
}

.datetimePicker{
    width: 100%;
    background: var(--white);
    outline: 1px solid var(--grey-4);
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 8px;
    min-width: 260px;
    transition: 0.2s ease-in-out;
    border: none;
}

.termForm .formInput{
    min-width: 100%;
}
.sessionCardHeaderLeft{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}

.sessionCardHeaderLeft .sessionDate{
    /* font-size: 12px; */
    color: var(--primary-4);
}

.sessionCardHeaderLeft .sessionDateContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    font-size: 12px;
}