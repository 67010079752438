.ticketRecordContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: var(--spacing-20px);
    gap: var(--spacing-8px);
    overflow: auto;
    height: calc(100vh - 105px);
}
.ticketDetailsContainer{
    position: relative;
    border-radius: 6px;
    background: var(--white);
    width: 700px;
    padding: var(--spacing-20px);
    height: auto;
    border: 1px solid var(--grey-3);
}
.ticketDetailsHeader{
    margin-bottom: var(--spacing-16px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ticketDetailsHeader .contentLeft{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    flex-direction: row;
    gap: var(--spacing-12px);
}

.ticketDetailsHeader img{
    padding: var(--spacing-4px);
    border-radius: 50%;
    background-color: var(--grey-2);
}


.ticketName{
    font-family: 'ns-bold';
}

.ticketDescription{
    margin-top: var(--spacing-12px);
    color: var(--black-2);
    font-size: 14px;
    line-height: 150%;
}

.ticketTable{
    margin-top: var(--spacing-12px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ticketTableElement{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-4px);
    width: max-content;
    flex-direction: column;
}

.ticketTableElement .heading{
    font-size: 14px;
    color: var(--black-1);
}

.ticketTableElement .content{
    font-family: 'ns-semibold';
    color: var(--black-4);
    font-size: 14px;
}

.ticketFeedbackContainer{
    position: relative;
    border-radius: 6px;
    background: var(--white);
    width: 700px;
    height: auto;
    padding: var(--spacing-20px);
    border: 1px solid var(--grey-3);
}

.ticketFeedbackContainer .heading{
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--black-4);
}

.feedbackOuter{
    margin-top: var(--spacing-20px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: var(--spacing-12px);
}

.feedbackBtn{
    cursor: pointer;
    transition: 0.3s;
    font-size: 14px;
    font-size: 'ns-semibold';
    color: var(--black-1);
    gap: var(--spacing-8px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    padding: var(--spacing-8px) var(--spacing-12px);
}

.feedbackBtn.active.green{
    color: var(--white);
    border-color: var(--success-4);
    background: var(--success-4);
}
.feedbackBtn.active.yellow{
    color: var(--black);
    border-color: var(--warning-7);
    background: var(--warning-7);
}
.feedbackBtn.active.red{
    color: var(--white);
    border-color: var(--danger-4);
    background: var(--danger-4);
}

.feedbackBtn.active svg path{
    fill: var(--white);
}

.feedbackBtn.active.yellow svg path{
    fill: var(--black-5);
}

.ticketConversationContainer{
    position: relative;
    border-radius: 6px;
    background: var(--white);
    width: 700px;
    height: auto;
    border: 1px solid var(--grey-3);
    
}

.ticketConversationContainer .heading{
    font-size: 14px;
    font-family: 'ns-semibold';
    padding: var(--spacing-20px);
}

.ticketConversationOuter{
    padding: 0 var(--spacing-20px) var(--spacing-20px) var(--spacing-20px);
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing-8px);
}

.ticketConversationContainer .messageInputBox{
    padding: var(--spacing-12px) var(--spacing-20px);
    position: relative;
}