.markAttendancePopupContainer {
    width: 550px;
    position: relative;
    background: var(--white);
    border-radius: 6px;
    overflow: hidden;
}

.markAttendancePopupContainer .popupFooter {
    position: relative;
}

.popupTableContainer {
    height: 400px;
    width: 100%;
    overflow: auto;
    border-radius: 6px;
    border: 1px solid var(--grey-3);

}

.markBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    border-radius: 6px;
    padding: var(--spacing-8px) var(--spacing-12px);
    border: 1px solid var(--grey-3);
    transition: 0.3s;
}

.markBtn.success:hover {
    border-color: var(--success-4);
}

.markBtn.success:hover svg path {
    fill: var(--success-4);
}

.markBtn.success.active {
    border-color: var(--success-4);
    background: var(--success-4);
}

.markBtn.success.active svg path {
    fill: var(--white);
}

.markBtn.danger:hover {
    border-color: var(--danger-4);
}

.markBtn.danger:hover svg path {
    fill: var(--danger-4);
}

.markBtn.danger.active {
    border-color: var(--danger-4);
    background: var(--danger-4);
}

.markBtn.danger.active svg path {
    fill: var(--white);
}

.markAttendancePopupContainer .programName {
    font-family: 'ns-bold';
    font-size: 20px;
    line-height: 26px;
}

p.subtitle {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
    margin-top: var(--spacing-4px);
    margin-bottom: var(--spacing-16px);
}

.markAttendaceheader{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.markAttendaceheader .checkboxContainer{
    margin: 0;
    padding-left: 30px;
    font-size: 12px;
    color: var(--white);
    font-family: 'ns-semibold';
}