.userListPopupContainer{
    position: relative;
    border-radius: 10px;
    width: 700px;
    margin: auto;
    height: auto;
    background: var(--white);
}

.userListTable{
    text-align: left;
    width: 100%;
}

.userListTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.userListTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.userListTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.userListTable tbody tr:hover td{
    background: var(--grey-1);
}

.userListTableOuter{
    border: 1px solid var(--grey-3);
    border-radius: 6px;
    overflow: auto;
    height: 500px;
}