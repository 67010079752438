.pageHeaderType2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: var(--white);
    padding: var(--spacing-16px) var(--spacing-20px);
}   
.pageHeaderLeft{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    gap: var(--spacing-12px);
}

.pageHeaderType2 .breadCrumbOuter{
    width: max-content;
}

.pageHeaderType2 .breadCrumbOuter .breadCrumb{
    width: max-content;
}

.pageHeaderRight{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 15%;
    gap: var(--spacing-12px);
}

.pageHeaderRight .percent{
    margin: 0;
    width: max-content;
}

.attendanceListContainerOuter{
    width: 100%;
    height: 100%;
    padding: 0 var(--spacing-20px);
}

.attendanceListContainer{
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    background: var(--white);
    height: calc(100vh - 190px);
    overflow: auto;
}

.attendanceListTable{
    position: relative;
    text-align: left;
    width: max-content;
    border-collapse: collapse;
}

.attendanceListTable th{
    border-right: 1px solid var(--black-1);
    background: var(--black-3);
    font-size: 14px;
    color: var(--white);
    padding: var(--spacing-12px) var(--spacing-8px);
}

.attendanceListTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;   
}

.attendanceListTable td{
    background: var(--white);
    border-right: 1px solid var(--grey-3);
    border-bottom: 1px solid var(--grey-3);
    padding: var(--spacing-16px) var(--spacing-16px);
}

.attendanceListTable tr td:last-child {
    border-right: none;
}


.day{
    text-align: center;
    width: 120px;
}

.session{
    width: 260px;
}


.attendanceListTable tr td:first-child{
    position: sticky;
    left: 0;
}
.attendanceListTable tr th:first-child{
    position: sticky;
    left: 0;
}

.dayInner{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dayInner p:first-child{
    width: max-content;
    font-family: 'ns-semibold';
}

.dayInner p:last-child{
    font-size: 12px;
    font-family: 'ns-light';
}

th{
    font-family: 'ns-semibold';
}

.filterBarRight .react-date-picker__wrapper{
    background: var(--grey-4);
}
.totalAttendanceOuter{
    padding: 0 !important;
}
.totalAttendance{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.totalAttendance .present{
    padding: 6px var(--spacing-4px);
    text-align: center;
    width: 100%;
    font-size: 12px;
    font-family: 'ns-regular';
    background: var(--success-1);
    color: var(--success-4);
}
.totalAttendance .absent{
    padding: 6px var(--spacing-4px);
    text-align: center;
    width: 100%;
    font-size: 12px;
    font-family: 'ns-regular';
    background: var(--danger-1);
    color: var(--danger-4);
}

.programAttendanceOuter{
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    width: 100%;
    gap: var(--spacing-8px);
}
.attendanceHeaderOuter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.btnGroup{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing-8px);
}