.subMenuOuter{
    width: 250px;
    height: 100%;
    background: var(--white);
}

.routeWithSubMenu {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: calc(100% - 70px);
}
.subMenuItem{
    cursor: pointer;
    border-bottom: 1px solid var(--grey-3);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-12px);
    padding: var(--spacing-16px) var(--spacing-28px);
    transition: 0.3s;
    text-decoration: none;
    color: inherit;    
}
.subMenuItem:hover{
    color: var(--primary-4);
    background: var(--primary-1);
}

.subMenuItem:hover svg path{
    fill: var(--primary-4);
}
.dropdownGroup{
    gap: var(--spacing-8px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: var(--spacing-20px);
}
.dropdownGroup .css-1s2u09g-control, .dropdownGroup .css-1pahdxg-control{
    width: 300px;
}
.subMenuItem.active {
    color: var(--primary-5);
    background: var(--primary-2);
}

.subMenuItem.active svg path {
    fill: var(--primary-5);
}