.announcementContainerOuter{
    width: 100%;
    border-radius: 6px;
    height: calc(100vh - 110px);
}

.annoucementTable{
    width: 100%;
    text-align: left;

}

.annoucementTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.annoucementTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.annoucementTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.annoucementTable tbody tr:hover td{
    background: var(--grey-1);
}

.createAssignmentFooter{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid var(--grey-3);
    padding: var(--spacing-16px) var(--spacing-20px);
}
.anouncementDesc span{
  width: 500px;
  position: absolute;
  left: 20px;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.anouncementDesc{
    width: 600px;
    position: relative;
}