.checkmark-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .checkmark-wrapper.col-3 .checkmark-label {
    flex: 0 0 32%;
    margin-bottom: 0;
  }

  .checkmark-wrapper.col-3 .checkmark-label:last-child {
    margin-right: 0;
  }

  .checkmark-label {
    position: relative;
    margin-right: 0;
    margin-bottom: 10px;
    color: var(--black-4);
    position: relative;
    padding: 13px 10px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-family: "ns-semibold";
    display: flex;
    align-items: center;
    border: 1px solid var(--black-4);
    border-radius: 10px;
  }
  
  .checkmark-label:last-child {
    margin-bottom: 0;
  }
  
  .checkmark-label.active {
    background: var(--warning-4);
  }
  
  .checkmark-btn {
    height: 16px;
    width: 16px;
    border: 1px solid var(--black-4);
    margin-right: 0;
    opacity: 0;
    position: absolute;
    z-index: 9;
}

.checkmarkspan {
    display: inline-block;
    background: transparent;
    height: 16px;
    width: 16px;
    border: 1px solid var(--black-4);
    margin-right: 12px;
    position: relative;
    z-index: 1;
    border-radius: 3px;
}

.checkmark-btn:checked ~ .checkmarkspan {
    display: inline-block;
    background: var(--black-4);
}

.checkmark-btn:checked ~ .checkmarkspan::after {
    opacity: 1;
}

.checkmarkspan::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 4px;
    width: 4px;
    height: 9px;
    border: 2px solid var(--white);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
}