
.feedbackContainer{
    width: 100%;
    height: 100%;
}

.pageHeader{
    min-height: 62px;
    align-items: center;
    border-bottom: 1px solid var(--grey-3);
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: var(--spacing-8px) var(--spacing-20px);
    background: var(--white);
}
.pageTogglers{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-20px);
}
.pageToggler{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
    font-size: 14px;
    font-family: 'ns-semibold';
    cursor: pointer;
}
.pageToggler.active svg path{
    fill: var(--primary-5);
}
.pageToggler.active{
    color: var(--primary-4);
}

.searchBox{
    border-radius: 5px;
    border: 1px solid var(--grey-3);
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: var(--spacing-8px);
    background: var(--white);
    gap: var(--spacing-8px);
}

.searchInput{
    width: 100%;
    border: none;
    outline: none;
    color: var(--grey-5);
    background: var(--white);
}

.filterBar{
  
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-8px) var(--spacing-20px);
}

.fiterBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-4px);
    border-radius: 6px;
    outline: none;
    padding: 10px;
    background: var(--white);
    border: 1px solid  var(--grey-5);
    font-size: 14px;
    color: var(--black-4);
    cursor: pointer;
}

.feedbackTableContainer{
    /* padding: 20px; */
    max-height: calc(100% - 30px);
    background: var(--white);
    width: calc(100% - 40px);
    margin: 20px;
    height: 100%;
    border-radius: 5px;
    overflow: auto;
    border: 1px solid var(--grey-3);
}

.feedbackTable{
    width: 100%;
    text-align: left;

}

.feedbackTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.feedbackTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.feedbackTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.feedbackTable tbody tr:hover td{
    background: var(--grey-1);
}

.feedbackTitle{
    gap: var(--spacing-8px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.tooltipOuter{
    position: relative;
}

.tooltipOuter img{
    cursor: pointer;
}



.tooltipContent{
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
    top: 100%;
    left: -30px;
    position: absolute;
    font-size: 12px;
    color: var(--white);
    border-radius: var(--spacing-4px);
    padding: var(--spacing-4px) var(--spacing-8px);
    background: var(--black-5);
}

.tooltipOuter img:hover + .tooltipContent{
    visibility: visible;
    opacity: 1;
}

.tooltipContent::before{
    content: '';
    width: 15px;
    height: 15px;
    background: var(--black-5);
    transform: rotate(45deg);
    position: absolute;
    left: 35%;
    top: -5px;
    z-index: -1;
}
.feedbackTable .feedbackTitle{
    max-width: 240px;
    width: max-content;
    display: block;
    overflow: hidden !important;
    -webkit-line-clamp: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.feedbackTitleOuter{
    width: 300px;
    overflow: hidden !important;
}

.toolTipContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.toolTipContainer .tooltipContent {
    top: -20%;
    left: 120%;
}

.toolTipContainer .tooltipContent::before{
   display: none;
}