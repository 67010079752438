.raiseTicketContainerOuter{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: var(--spacing-20px);
    height: calc(100vh - 105px);
}
.raiseTicketContainer{
    position: relative;
    border-radius: 6px;
    background: var(--white);
    width: 700px;
    height: 100%;
    border: 1px solid var(--grey-3);
}

.raiseTicketContainerInner{
    padding: var(--spacing-20px);
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing-16px);
}
.raiseTicketFooter{
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid var(--grey-3);
    padding: var(--spacing-16px) var(--spacing-20px);
}