.termPlanDetailContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-20px);
    width: 1200px;
    margin: auto;
    padding: var(--spacing-20px);
}

.termMenuOuter {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-12px);
}

.termPlanDetailsHeading {
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--black-4);
    text-transform: uppercase;
}

.termMenu {
    height: 570px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing-8px);
}

.termMenuItem {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    border-radius: 6px;
}
.termMenuItem.active{
    border: 1px solid var(--grey-3);
    background: var(--white);
}
.termMenuItem.active .termPlanBody{
    display: block;
}
.termMenuItem.active .termHeader{
    border-bottom: 1px solid var(--grey-3);
}
.termMenuItem.active .termHeader img{
    transform: rotate(180deg);
}
.termHeader {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8px);
    padding: var(--spacing-20px) var(--spacing-16px);
    
}

.completedTag {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
    color: var(--success-4);
    font-size: 14px;
}

.termMenuHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.termPlanBody {
    display: none;
    padding: var(--spacing-20px) var(--spacing-16px);
}

.program {
    cursor: pointer;
    padding: var(--spacing-12px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--success-1);
    border-radius: 6px;
}

.program .programName {
    color: var(--success-4);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
}

.termMenuHeader p {
    font-size: 14px;
}

.termForm {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-12px);
}

.termForm .input {
    width: 32%;
}

.input .react-date-picker__wrapper {
    min-width: 100% !important;
}

.input .formGroup {
    gap: 5px;
}

.termFormContainer {
    width: calc(100% - 370px);
    background: var(--white);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
}

.termFormFooter{
    padding: var(--spacing-20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid var(--grey-3);
}

.termFormOuter{
    height: 300px;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-20px);
    padding: var(--spacing-20px);
}