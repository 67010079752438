.formInput::placeholder{
    color: var(--grey-5);
    transition: 0.2s ease-in-out;
}

.formGroup label{
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.formInput{
    width: 100%;
    background: var(--white);
    outline: 1px solid var(--grey-4);
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 8px;
    min-width: 260px;
    transition: 0.2s ease-in-out;
    border: none;
}

.formInput:hover{
    outline-color: var(--grey-5);
    transition: 0.2s ease-in-out;
}

.formInput:focus{
    outline: 2px solid var(--primary-6);
    transition: 0.2s ease-in-out;
}

.formInput:hover::placeholder{
    color: var(--black-1);
    transition: 0.2s ease-in-out;
}

.formInput:disabled{
    background: var(--grey-3);
    outline-color: var(--grey-3);
    color: var(--grey-5);
}

.formInput:disabled:hover{
    outline-color: var(--grey-3);
}

.formInput:disabled:hover::placeholder{
    color: var(--grey-5);
}

.formInput.error{
    outline-color: var(--danger-6);
    transition: 0.2s ease-in-out;
}

.formInput.error::placeholder{
    color: var(--black-4);
    transition: 0.2s ease-in-out;
}

.formInput.error:focus{
    outline: 2px solid var(--danger-6);
    transition: 0.2s ease-in-out;
}

.error-msg{
    color: var(--danger-6);
    font-size: 12px;
    line-height: 16px;
}

.impMark{
    font-family: 'ns-regular';
    color: var(--danger-6);
}