.meetingListContainerOuter {
    width: calc(100% - 40px);
    margin: auto;
    background: var(--white);
    border-radius: 6px;
    height: calc(100% - 130px);
    overflow: auto;
}

.meetingListTable {
    width: max-content;
    text-align: left;

}

.meetingListTable thead {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.meetingListTable thead th {
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.meetingListTable tbody td {
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.meetingListTable tbody tr:hover td {
    background: var(--grey-1);
}



.overflowText{
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}