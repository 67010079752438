.allCourseAttendanceContainerOuter {
    width: 100%;
    height: calc(100% - 70px);
    overflow: auto;
}

.allCourseAttendanceContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: calc(100% - 40px);
    margin: auto;
    height: calc(100% - 60px);
    overflow: auto;
    background: var(--white);
    border: 1px solid var(--grey-3);
    border-radius: 6px;
}


.allCourseAttendanceTable {
    width: 100%;
    text-align: left;

}

.allCourseAttendanceTable thead {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.allCourseAttendanceTable thead th {
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.allCourseAttendanceTable tbody td {
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.allCourseAttendanceTable tbody tr:hover td {
    background: var(--grey-1);
}