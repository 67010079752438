.eventDetailsOuter .clubHeaderOuter{
    height: 260px;
}

.detailedDescOfEvent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--white);
    padding: var(--spacing-20px);
}

.dateTime{
    margin: var(--spacing-16px) 0 var(--spacing-12px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: var(--spacing-8px);
}

.detailedDescOfEvent .heading{
    font-size: 12px;
    font-family: 'ns-bold';

}

.detailedDescOfEvent .details{
    margin-top: var(--spacing-12px);
    font-size: 12px;
    color: var(--black-1);
}
.dateTimeContent{
    gap: var(--spacing-4px);
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.eventFullDetails{
    padding: var(--spacing-20px);
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid var(--grey-3);
    background: var(--white);
    border-radius: 6px;
    gap: var(--spacing-16px);
}

.eventFullDetailsInner{
    gap: var(--spacing-4px);
    width: 48%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.eventFullDetailsInner .heading{
    font-size: 14px;
    color: var(--black-1);
}

.eventFullDetailsInner .content{
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--black-5);
}

.eventFullDetailsInner:nth-last-child(-n+2){
    width: 100%;
}

.tagsOuter{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}
.darkTagBig{
    background: var(--black-3);
    width: max-content;
    font-size: 12px;
    color: var(--white);
    padding: var(--spacing-4px) var(--spacing-8px);
    border-radius: 6px;
}

.eventCommentSection{
    width: 100%;
    position: relative;
    background: var(--white);
    border: 1px solid var(--grey-3);
}

.eventCommentSection .messageInputBox{
    position: relative;
}

.eventCommentSection .heading{
    font-size: 12px;
    padding: var(--spacing-20px) var(--spacing-20px) 0;
    color: var(--black-3);
    font-family: 'ns-semibold';
}