.pageHeaderType3 {
    width: 100%;
    padding: var(--spacing-8px) var(--spacing-20px);
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.backBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--black-1);
}

.formTogglers {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-32px);
}

.formToggler {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
}

.formToggler p {
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--black-1);
}

.formToggler.active p {
    color: var(--success-4);
}

.formToggler.active svg path {
    fill: var(--success-4);
}

.createClubContainer {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: var(--spacing-20px);
    height: calc(100vh - 120px);
    overflow: auto;
}

.createClubContainerOne {
    width: 700px;
    position: relative;
    height: 800px;
    border-radius: 6px;
    background: var(--white);
    overflow: hidden;
}

.formOuter {
    width: 100%;
    gap: var(--spacing-16px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: first baseline;
    padding: var(--spacing-20px);
}

.createClubContainerFooter {
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    bottom: 0;
    position: absolute;
    border-top: 1px solid var(--grey-3);
    padding: var(--spacing-16px) var(--spacing-20px);
}

.createClubHeader {
    width: 100%;
    height: 200px;
    position: relative;
    border-radius: 6px 6px 0 0;
}

.addClubBackgroundImg {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.clubBackgroundImg {
    width: 100%;
    object-fit: cover;
}

.clubProfile {
    top: 70%;
    left: 20px;
    position: absolute;
}

.clubProfilePhoto {
    height: 72px;
    width: 72px;
    border-radius: 50%;
    object-fit: cover;
}

.addProfilePhoto {
    top: 60%;
    position: absolute;
    padding: 5px;
    border-radius: 50%;
    background: var(--grey-3);
    left: 50px;
    cursor: pointer;
}

.createContainerSecond {
    position: relative;
    width: 850px;
    height: 100%;
    border-radius: 6px;
    overflow: auto;
    background: var(--white);

}

.createContainerSecondInner {
    padding: var(--spacing-20px);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16px);
}

.createContainerTableOuter {
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    height: 335px;
    overflow: auto;

}

.createClubTable {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}

.createClubTable thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background: var(--black-4);
}

.createClubTable th {
    padding: var(--spacing-12px) var(--spacing-20px);
    font-size: 14px;
    line-height: 120%;
    color: var(--white);
    font-family: 'ns-semibold';
}

.createClubTable td {
    padding: var(--spacing-8px) var(--spacing-20px);
    font-size: 14px;
    line-height: 120%;
    color: var(--black-4);
    font-family: 'ns-regular';
    border-bottom: 1px solid var(--grey-3);
}

.deleteIcon {
    cursor: pointer;
    padding: 6px;
    border-radius: 50%;
    background: var(--grey-1);
}

.flexEnd {
    justify-content: flex-end;
}

.createContainerThird {
    width: 1000px;
    height: 100%;
    position: relative;
}

.filterDropdwon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}

.addMembersTableOuter {
    width: 100%;
    border-radius: 6px;
    overflow: auto;
    background: var(--white);
    height: calc(100% - 115px);
}

.addMemberTable {
    width: 100%;
    text-align: left;
}

.addMemberTable thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background: var(--black-4);
}

.addMemberTable th {
    padding: var(--spacing-12px) var(--spacing-20px);
    font-size: 14px;
    line-height: 120%;
    color: var(--white);
    font-family: 'ns-semibold';
}

.addMemberTable td {
    background: var(--white);
    padding: var(--spacing-12px) var(--spacing-20px);
    font-size: 14px;
    line-height: 120%;
    color: var(--black-4);
    font-family: 'ns-regular';
    border-bottom: 1px solid var(--grey-3);
}

.createContainerThird .filterBar {
    padding: 0;
    margin-bottom: var(--spacing-12px);
}