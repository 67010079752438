.togglerContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}
.toggler{
    cursor: pointer;
    position: relative;
    width: 45px;
    height: 20px;
    background: var(--grey-5);
    border-radius: 30px;
    transition: 0.3s;
}

.togglerBtn{
    transition: 0.3s;
    position: absolute;
    left: -3px;
    top: -3px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: var(--grey-3);
}
.toggler.active{
    background: var(--primary-2);
}
.toggler.active .togglerBtn{
    left: 23px;
    background: var(--primary-4);
}

.togglerContainer p{
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--black-3);
}

.courseBiddingTableContainer{
    position: relative;
    width: calc(100% - 40px);
    margin: 0 auto;
    height: calc(100% - 150px);
    background: var(--white);
    border-radius: 6px;
    overflow: auto;
}

.courseBiddingTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}
.courseBiddingTable{
    width: 100%;
    text-align: left;
}

.courseBiddingTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.courseBiddingTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.courseBiddingTable tbody tr:hover td{
    background: var(--grey-1);
}

.courseBiddingFooter{
    display: flex;
    width: 100%;
    position: sticky;
    bottom: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-20px);
    background: var(--white);
}

.courseBiddingTableOuter{
    overflow: auto;
    width: 100%;
    height: calc(100% - 75px);
}

.courseBtn{
    cursor: pointer;
    font-size: 12px;
    font-family: 'ns-semibold';
    color: var(--black-5);
    background: var(--white);
    border: 2px solid var(--black-1);
    border-radius: 6px;
    padding: 6px 8px;
    transition: 0.3s;
}

.courseBtnOuter{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.courseBtn.active{
    background: var(--black-1);
    color: var(--white);

}
.tableCheckbox{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
}

.customSelect{
    cursor: pointer;
    font-size: 12px;
    font-family: 'ns-semibold';
    color: var(--black-5);
    background: var(--white);
    border: 2px solid var(--black-1);
    border-radius: 6px;
    padding: 6px 8px;
    transition: 0.3s;
}

.courseBiddingStudent{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}

.greenDot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--success-2);
}

.greenDot.active{
    background: var(--success-5);
}