.gradesOuter{
    height: calc(100vh - 105px);
}

.gradesOuter .courseDetailsMenuHeading{
    margin-top: 0;
}

.studentProfileGradeBox{
    background: var(--white);
    border: 1px solid var(--grey-3);
    border-radius: 6px;
    padding: var(--spacing-20px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.studentProfileGradeBox .profileImg{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid var(--black-3);
}

.studentProfileGradeBoxLeft{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}

.progesssCotent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--spacing-4px);
}

.progesssCotent .progressText{
    font-size: 12px;
    color: var(--black-1);
    font-family: 'ns-semibold';
}

.contentLeft {
    display: flex;
    flex-direction: column;
    width: calc(100% - 70px);
}

.contentLeft .name{
    font-size: 18px;
    font-family: 'ns-bold';
}
.studentProfileGradeBoxRight{
    border-left: 1px solid var(--grey-3);
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: var(--spacing-4px);
    padding-left: var(--spacing-20px);
    justify-content: center;
    align-items: flex-start;
}

.studentProfileGradeBoxRight .heading{
    font-size: 12px;
    text-transform: uppercase;
    color: var(--black-1);
}

.studentProfileGradeBoxRight .cgp{
    font-size: 18px;
    color: var(--success-4);
    font-family: 'ns-bold';
}

.courseTagBox{
    margin: var(--spacing-8px) 0 var(--spacing-12px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.courseTagInner{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-4px);
    flex-direction: column;
}

.courseTagInner .courseNumber{
    font-size: 12px;
    text-transform: uppercase;
    font-family: 'ns-regular';
    color: var(--black-1);
}

.courseTagInner .courseName{
    font-size: 16px;
    font-family: 'ns-bold';
    color: var(--black-4);
}

.courseMarksTable{
    display: none;
    width: 100%;
    text-align: left;
    margin-top: var(--spacing-12px);
}

.courseMarksTable td:last-child{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.courseMarksTable tr:last-child td{
    padding-bottom: 0;
}

.courseMarksTable td{
    font-size: 14px;
    padding: var(--spacing-12px) 0;
    border-top: 1px solid  var(--grey-3);
}

.courseMarksOuter{
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    background: var(--white);
    padding: var(--spacing-20px);
}

.courseMarksHeader{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.courseMarksHeaderElement{
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4px);
}

.courseMarksHeaderElement .heading{
    font-size: 12px;
    text-transform: uppercase;
    color: var(--black-1);
}

.courseMarksHeaderElement .text{
    font-family: 'ns-semibold';
    font-size: 14px;
}

.courseMarksTableOuter{
    width: 100%;
    text-align: left;
}

.courseMarksTable.active{
    display: inline-table;
}

.gradesOuter .sessionOverviewCard .courseNumber{
    text-transform: capitalize;
    font-size: 14px;
    font-family: 'ns-semibold';
    margin-bottom: var(--spacing-8px);
}
.gradesOuter .sessionOverviewCard .sessionHeading{
    font-family: 'ns-semibold';
}
.gradesOuter .sessionOverviewCard.active .sessionHeading{
    color: var(--primary-4);
}

.gradesOuter .sessionOverviewCard.active svg path{
    fill: var(--primary-4);
}

.gradesOuter .courseContentOuter .courseHeading{
    font-family: 'ns-semibold';
    font-size: 18px;

}



.gradeTable{
    position: relative;
    width: max-content !important;
}

.studentName{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
    cursor: pointer;
}
.gradeTable th:first-child{
    position: sticky;
    left: 0px;
}
.gradeTable td:first-child{
    position: sticky;
    left: 0px;
    background:var(--white);
}
.gradeTable td{
    border-right: 1px solid var(--grey-3);
}
.assignmentListTable.gradeTable td .formInput{
    min-width: 130px !important;
    width: 130px;
}

.fixedTable tr td:nth-last-of-type(1), .fixedTable tr th:nth-last-of-type(1) {
    position: fixed;
    width: 170px;
    right: 20px;
    padding: 9px 20px !important;
}
.fixedTable tr td:nth-last-of-type(2), .fixedTable tr th:nth-last-of-type(2) {
    position: fixed;
    right: 190px;
    width: 173px;
    padding: 9px 20px !important;
}
.fixedTable tr td:nth-last-of-type(3), .fixedTable tr th:nth-last-of-type(3) {
    position: fixed;
    right: 363px;
    width: 173px;
    padding: 9px 20px !important;
}

.fixedTable th, .fixedTable td{
    padding: 15px 20px !important;
}
.fixedTable{
    position: relative;
}

.assignmentListTable.gradeTable.fixedTable th:nth-last-child(-n+3){
    padding: 15px 20px !important;
}