.sideMenu {
    height: calc(100vh - 49px);
    width: 85px;
    background: var(--black-5);
    transition: 0.3s;
}

.sideMenu a {
    text-decoration: none;
}

.sideMenuOuter.active .sideMenu {
    left: 0px;
}

.sideMenuList {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.sideMenuListItem {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 16px 10px;
    font-family: 'ns-regular';
    color: var(--white);
    font-size: 12px;
    transition: 0.3s;
}

.sideMenuListItem:hover {
    background: var(--primary-4);
}

.border-top {
    border-top: 1px solid var(--black-4);
}

.sideMenuOuter {
    position: relative;
    height: calc(100% - 49px);
    width: 85px;
}

.sideMenuOuter.active {
    visibility: visible;
    opacity: 1;
}