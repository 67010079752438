.leaveListContainerOuter{
    width: 100%;
    height: calc(100% - 70px);
    overflow: auto;
}

.greyTagBig {
    width: max-content;
    font-size: 14px;
    line-height: 16px;
    font-family: 'ns-semibold';
    padding: var(--spacing-12px) var(--spacing-8px);
    color: var(--black-4);
    border-radius: 6px;
    background-color: var(--grey-3);
}

.filterBarRight{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}

.leaveListContainer{
    border-radius: 6px;
    overflow: auto;
    height: calc(100% - 60px);
    background: var(--white);
    width: calc(100% - 40px);
    margin: auto;
    border: 1px solid var(--grey-3);
}

.leaveListTable{
    width: 100%;
    text-align: left;

}

.leaveListTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.leaveListTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.leaveListTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.leaveListTable tbody tr:hover td{
    background: var(--grey-1);
}

.actionBtn{
    border: none;
    background: transparent;
    outline: none;
    position: relative;
}

.actionBtn img{
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
}

.actionBtn.active img{
    background: var(--grey-2);
}

.actionDropdownList{
    min-width: 160px;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    border: 1px solid var(--grey-3);
    position: absolute;
    top: 80%;
    right: 50%;
    width: max-content;
    list-style: none;
    background: var(--white);
    z-index: 1;
    border-radius: 6px;
    box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
}

.actionDropdownListItem{
    border-bottom: 1px solid var(--grey-3);
    font-family: 'ns-regular';
    font-size: 14px;
    color: var(--black-4);
    cursor: pointer;
    transition: 0.3s;
    gap: var(--spacing-8px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: var(--spacing-8px);
}
.actionDropdownListItem.normalItem{
    color: var(--black-4) !important;
}
.actionDropdownListItem.normalItem:hover svg path{
    fill: var(--white) !important;
}

.actionDropdownListItem.normalItem:hover{
    background: var(--black-4) !important;
    color: var(--white) !important;
}

.actionDropdownListItem:last-child{
    border: none;
    color: var(--danger-4);
}

.actionDropdownListItem:hover{
    background: var(--black-4);
    color: var(--white);
}

.actionDropdownListItem:last-child:hover{
    background: var(--danger-4);
    color: var(--white);
}

.actionDropdownListItem:last-child:hover svg path{
    fill: var(--white);
}

.actionDropdownListItem:hover svg path{
    fill: var(--white);
}

.actionBtn.active .actionDropdownList{
    visibility: visible;
    opacity: 1;
}