.meetingTimePopupContainer{
    position: relative;
    width: 420px;
    height: auto;
    background: var(--white);
    border-radius: 6px;
}

.meetingLinkOuter{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}

.meetingLink p{
    font-size: 14px;
    
}

.meetingTimePopupContainer .popupFooter{
    border: none;
    position: relative;
}