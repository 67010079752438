.studentListTable tbody td{
    padding: 15px 20px;
}

.textBlue{
    color: var(--primary-6) !important;
}

.progressAlign{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    color: var(--success-6);
    font-family: 'ns-semibold';
}

.studentImage{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.studentImage img{
    max-width: 32px;
    object-fit: cover;
    border-radius: 50%;
}

.courseListTable tbody td{
    padding: 10px 20px;
}