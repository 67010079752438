.popupOuter{
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    padding: 20px;
    z-index: 10;
    background:  rgba(23, 23, 23, 0.4);
    backdrop-filter: blur(5px);
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
}

.popupContainer{
    position: relative;
    border-radius: 10px;
    width: 700px;
    margin: auto;
    height: 700px;
    background: var(--white);
}
.popupOuter.active{
    visibility: visible;
    opacity: 1;
}
.popupHeader{
    display: flex;
    align-items:  center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 16px;
    border-bottom: 1px solid var(--grey-3);
}

.popupTitle{
    font-family: 'ns-semibold';
    font-size: 16px;
    line-height: 22px;
    color: var(--black-5);
}

.timer{
    padding: 6px 8px;
    border-radius: 6px;
    background: var(--danger-1);
}

.timer p{
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    color: var(--black-5);
}
.timer span{
    font-family: 'ns-semibold';
    color: var(--danger-5);
}

.popupBody{
    padding: 20px 16px;
    width: 100%;
}
.question{
    color: var(--black-3);
    font-size: 18px;
    line-height: 24px;
    font-family: 'ns-regular';
}



[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: var(--black-3);
    width: 100%;
    font-family: 'ns-semibold';
}
[type="radio"]:checked + label{
    color: var(--primary-4);
}

.option:focus-within{
    border-color:var(--primary-4) ;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 1px;
    width: 12px;
    height: 12px;
    border: 2px solid var(--black-3);
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:before{
    border-color: var(--primary-4);
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: var(--primary-4);
    position: absolute;
    top: 5px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.optionsOuter{
    margin-top: var(--spacing-12px);
    gap: var(--spacing-8px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.option{
    width: 100%;
    border: 1px solid var(--grey-3);
    border-radius: 6px;
    padding: 15px 12px;
}

.popupFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    padding: 16px;
    position: absolute;
    border-top: 1px solid var(--grey-3);
    width: 100%;
}

.popupContainer .pointerScale{
    margin: 0;
}

.resultSection{
    width: 100%;
    border-top: 1px solid var(--primary-4);
    border-bottom: 1px solid var(--primary-4);
    background: var(--primary-1);
    padding: 12px 16px;
}

.resultSection p{
    font-size: 32px;
    color: var(--black-5);
    font-family: 'ns-semibold';
}

.resultSection span{
    color: var(--primary-4);
    font-family: 'ns-semibold';
}

.option.wrong{
    background: var(--danger-1);
    border-color: var(--danger-1);
}

.option.correct{
    background: var(--success-1);
    border-color: var(--success-4);
}

.option.wrong label{
    color: var(--danger-5);
}

.option.correct label{
    color: var(--success-4);
}
.option.correct [type="radio"]:checked + label:after{
    background: var(--success-4);
}

.option.correct [type="radio"]:checked + label:before{
    border-color: var(--success-4);
}