.createContainerForth {
    position: relative;
    width: 800px;
    height: 100%;
    background: var(--white);
    border-radius: 6px;
    border: 1px solid var(--grey-3);
}

.userImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--black-4);
}

.conversationBoxOuter {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing-8px);
    padding: var(--spacing-20px);
    height: calc(100% - 80px);
    overflow: auto;
}

.coversationBoxLeft {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-8px);

}

.coversationBoxRight {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-8px);

}

.coversationBoxLeft .messageBox {
    border-radius: 6px;
    padding: var(--spacing-8px) var(--spacing-20px);
    background: var(--warning-1);
}

.coversationBoxRight .messageBox {
    border-radius: 6px;
    padding: var(--spacing-8px) var(--spacing-20px);
    background: var(--success-1);
}

.messageBox .message {
    font-size: 14px;
    line-height: 150%;
    color: var(--black-4);
}

.messageBoxOuter .time {
    margin-top: var(--spacing-4px);
    font-size: 10px;
    line-height: 120%;
    color: var(--grey-5);
}

.coversationBoxRight .messageBoxOuter .time {
    text-align: right;
}

.messageInputBox {
    background: var(--white);
    position: absolute;
    bottom: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-16px) var(--spacing-20px);
    width: 100%;
    display: flex;
    border-top: 1px solid var(--grey-3);
}

.messageInput {
    width: 88%;
    color: var(--black-4);
    font-size: 16px;
    line-height: 150%;
    outline: none;
    border: none;
}