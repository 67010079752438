.fullScreenWrapper{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

/*** Sucess css ***/
.feedback-success {
    border: 3px solid var(--success-4);
    color: var(--success-4);
}
.feedback-success .singleselect-btn:checked ~ .singleselectradio::after {
    background: var(--success-4) !important;
}

.feedback-success span.singleselectradio {
    border-color: var(--success-4) !important;
}

/*** danger css ***/
.feedback-danger {
    border: 3px solid var(--danger-4);
    color: var(--danger-4);

}
.feedback-danger .singleselect-btn:checked ~ .singleselectradio::after {
    background: var(--danger-4) !important;
}

.feedback-danger span.singleselectradio {
    border-color: var(--danger-4) !important;
}


.feedback-warning {
    background-color: var(--warning-2);
}



