*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'ns-regular';
}

.appOuter{
  height: 100vh;
  width: 100%;
  overflow: auto;
  background: var(--grey-2);
}

.appContainer{
  width: 100%;
  height: 100%;
  max-height:100%;
  overflow: auto;
}

.appOuterContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.appOuterContainerRight {
  width: calc(100% - 85px);
  height: calc(100vh - 50px);
}

.loginPageSet{
  width: 100%;
  height: 100%;
}