.fileViewerOuter{
    cursor: pointer;
    padding: var(--spacing-8px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid var(--grey-3);
    background: var(--grey-1);
    width: max-content;
    gap: var(--spacing-8px);
    min-width: 200px;
    border-radius: 8px;
}
.fileViewContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.fileViewContent .fileName{
    font-size: 14px;
    line-height: 20px;
    font-family: 'ns-bold';
    color: var(--black-5);
}

.fileViewContent .fileSize{
    font-size: 12px;
    line-height: 16px;
    color: var(--black-1);
}