.interviewListContainer{
    width: calc(100% - 40px);
    margin: auto;
    display: block;
    height: calc(100% - 120px);
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    overflow: auto;
    background: var(--white);
}
.interviewListTable{
    width: 100%;
    text-align: left;

}

.interviewListTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.interviewListTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.interviewListTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.interviewListTable tbody tr:hover td{
    background: var(--grey-1);
}

.createInterviewSlotContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-20px);
    width: 700px;
    margin: 20px auto;
    padding: var(--spacing-20px);
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    background: var(--white);
}
.createInterviewSlotContainer .react-date-picker__wrapper{
    max-width: 250px;
}
.createInterviewSlotContainer .formGroup{
    gap: 5px;
}

.createInterviewSlotContainer .feedbackRadioGroup{
    justify-content: flex-start;
    gap: 10px;
}

.slotTableOuter{
    height: 300px;
    border-radius: 6px;
    overflow: auto;
    border: 1px solid var(--grey-3);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding:0 var(--spacing-8px);

}
.slotTable{
    width: 100%;
    text-align: left;
}

.slotTable td{
    font-size: 14px;
    padding: 10px;
    border-bottom: 1px solid var(--grey-3);
}

.timeSlot{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
}

.timeSlot .rc-time-picker-input{
    width: 80px;
}

.slotTable td:first-child{
    width: 10px;
    padding: 0;
}

.slotTable td:nth-child(2){
    font-family: 'ns-semibold';
}

.slotTable .checkboxContainer{
    top: -4px;
}

.timeSlots{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}

.slotTable tr:last-child td{
    border-bottom: none;
}

.selectInterviewSlotContainer{
    width: 650px;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    border-radius: 6px;
    overflow: auto;
    background: var(--white);
    border: 1px solid var(--grey-3);
}
.selectInterviewSlotContainerHeader{
    background: var(--black-5);
    color: var(--white);
    font-size: 16px;
    font-family: 'ns-semibold';
    padding: var(--spacing-20px) var(--spacing-16px);
}

.selectInterviewContainer{
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 10px 15px;
    border-right: 1px solid var(--grey-3);
}
.selectInterviewContainer .react-calendar{
    width: 100%;
    max-width: 100%;
    border: none;
}
.selectInterviewContainer .react-calendar__tile{
    padding: 16px 12px;
    max-height: 50px;
}
.selectInterviewContainer .react-calendar .react-calendar__navigation__arrow{
    border-radius: 50%;
}

.selectInterviewContainerInner{
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.selectInterviewContainer:last-child{
    width: 250px;
    border: none;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}
.slot{
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    padding: 6px;
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--primary-4);
    width: 100%;
    border-radius: 6px;
    border: 2px solid var(--primary-4);
}

.slots.active .slot{
    width: 50%;
    background: var(--black-5);
    border-color: var(--black-5);
    color: var(--white);
}
.slots{
    width: 100%;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
}
.slots.active .primaryBtn{
    width: 50%;
    display: block;
}

.slots .primaryBtn{
    display: none;
}

.slotsOuter{
    margin-top: var(--spacing-16px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}
