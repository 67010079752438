.breadCrumbOuter{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    background: var(--white);
    padding: var(--spacing-12px);
}
.breadCrumb{
    color: var(--black-1);
    gap: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.breadCrumb  p{
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 120%;
}

/* .breadCrumb p:hover{
    text-decoration: underline;
    cursor: pointer;
    color: var(--primary-6);
} */

.breadCrumb:last-child{
    color: var(--black-4);
}

.breadCrumb:last-child span{
    display: none;
}